import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from 'grommet';

import ProductBreadcrumbHero from '../Product/ProductBreadcrumbHero';
import Buybar from '../Buybar';
import usePageBodyComponents from '../DynamicPage/usePageBodyComponents';
import PoliteProductBuyOptions from '../Product/PoliteProductBuyOptions';
import DefaultPdpHero from '../Product/DefaultPdpHero';

const PageProduct = ({
  product,
  relatedProducts = [],
  reviews,
  isScrolledPastShopButtons,
  pdpTemplate,
}) => {
  const pageComponents = usePageBodyComponents(pdpTemplate, {
    product,
    relatedProducts,
    reviews,
    identifier: product.handle,
  });

  const addToCartLabel = `Add ${
    product.productType.charAt(product.productType.length - 1) === 's'
      ? product.productType.slice(0, product.productType.length - 1)
      : product.productType
  }`;

  return (
    <Box>
      <Stack anchor="bottom">
        <Box>
          <ProductBreadcrumbHero
            product={{
              title: product.title,
              productType: product.productType,
              handle: product.handle,
              tags: product.tags,
            }}
            base={{
              title: 'Rugs',
              slug: 'collections/rugs/',
            }}
          />
          <DefaultPdpHero
            title={product.title}
            description={product.description}
            variants={product.variants}
            images={product.media.map((x) => x.image)}
            product={product}
            addToCartLabel={addToCartLabel}
          />
          {pageComponents}
        </Box>
        <Buybar position="bottom-right">
          <PoliteProductBuyOptions
            product={product}
            imageUrl={
              product.media[0] ? product.media[0].image.originalSrc : undefined
            }
            buybarVisible={isScrolledPastShopButtons}
            addToCartLabel={addToCartLabel}
          />
        </Buybar>
      </Stack>
    </Box>
  );
};

PageProduct.propTypes = {
  product: PropTypes.object.isRequired,
  relatedProducts: PropTypes.array,
  relatedColorProducts: PropTypes.array,
  content: PropTypes.object,
  pdpTemplate: PropTypes.object,
  reviews: PropTypes.array,
  isScrolledPastShopButtons: PropTypes.bool,
};

export default memo(PageProduct);
